<template>
  <trev-contact-zoho-tickets-table :companyId="companyId" :contactId="contactId"></trev-contact-zoho-tickets-table>
</template>

<script>
import trevContactZohoTicketsTable from '../../../../../components/ZohoTickets/trev-contact-zoho-tickets-table.vue'
export default {
  components: { trevContactZohoTicketsTable },
  props: {
    contactId: {
        type: String,
        default: ''
    },
    companyId: {
      type: String,
      default: ''
    }
  },
}
</script>

<style>

</style>