<template>
  <div>
    <v-form>
      <v-text-field label="Job Title" v-model="newContact.jobTitle">
      </v-text-field>
      <v-text-field label="First Name" v-model="newContact.firstName">
      </v-text-field>
      <v-text-field label="Last Name" v-model="newContact.lastName">
      </v-text-field>
      <v-text-field label="Email Address" v-model="newContact.emailAddress">
      </v-text-field>
      <v-combobox
        v-model="tags"
        :items="['VIP','Approver' ,'Service News', 'Billing','Support User']"
        label="Tags"
        multiple
        chips
        deletable-chips
      ></v-combobox>
      <v-text-field label="Mobile" v-model="newContact.mobileNumber">
      </v-text-field>
      <v-text-field label="Landline" v-model="newContact.landLine">
      </v-text-field>
    </v-form>
    <v-snackbar v-model="showSnackBar" :timeout="1500" color="success">
      {{ snackBarMessage }}
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: {
    companyId: {
      type: String,
      default: "",
    },
    contactId: {
      type: String,
      default: "",
    },
    resetFormAfterSubmit: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    if (this.contactId) {
      this.$courier.Contacts.getById(this.contactId).then((con) => {
        this.newContact = con;

        this.getTags();
      });
    }
  },
  methods: {
    async getTags() {
      this.loadedTags = (
        await this.$courier.Contacts.withId(this.contactId).Tags.getData(
          1,
          100,
          ""
        )
      ).payload;

      this.tags = this.loadedTags.map((x) => x.tag);
    },
    async updateContact() {
      var contact = await this.$courier.Contacts.getById(this.contactId);

      contact.firstName = this.newContact.firstName;
      contact.lastName = this.newContact.lastName;
      contact.jobTitle = this.newContact.jobTitle;
      contact.emailAddress = this.newContact.emailAddress;
      contact.mobileNumber = this.newContact.mobileNumber;
      contact.landLine = this.newContact.landLine;

      console.log(contact);
      var addedContact = await this.$courier.Contacts.updateById(
        contact.id,
        contact
      );

      if (this.loadedTags.length > 0) {
        for (const tag of this.loadedTags) {
          await this.$courier.ContactTags.removeById(tag.id);
        }
      }

      if (this.tags.length > 0) {
        for (const tag of this.tags) {
          await this.$courier.Contacts.withId(this.contactId).Tags.add({
            tag: tag,
          });
        }
      }

      this.$emit("added-contact", addedContact);
    },
    async saveContact() {
      if (this.contactId) {
        await this.updateContact();
      } else {
        this.addContact();
      }

      this.snackBarMessage =
        "Successfully " +
        (this.contactId ? "Updated" : "Added") +
        this.newContact.firstName;
      this.showSnackBar = true;

      if (this.resetFormAfterSubmit) {
        //reset
        this.newContact = {
          firstName: "",
          lastName: "",
          jobTitle: "",
          emailAddress: "",
          mobileNumber: "",
          landLine: "",
          tags: [],
        };

        this.tags = [];
      }
    },
    addContact() {
      this.newContact.tags = this.tags.map((x) => ({ tag: x }));
      this.$courier.Companies.withId(this.companyId)
        .Contacts.add(this.newContact)
        .then((addedContact) => {
          this.$emit("added-contact", addedContact);
        })
        .catch((error) => {
          console.log(error);

          this.snackBarMessage =
            "There was an error adding " +
            this.newContact.firstName +
            " See: " +
            error;
          this.showSnackBar = true;
        });
    },
  },
  data: () => ({
    loadedTags: [],
    tags: [],
    showSnackBar: false,
    snackBarMessage: "",
    snackbarType: "",
    newContact: {
      tags: [],
      firstName: "",
      lastName: "",
      jobTitle: "",
      emailAddress: "",
      mobileNumber: "",
      landLine: "",
    },
  }),
};
</script>

<style>
</style>