var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-0 mr-4"},[_c('v-card-text',[_c('p',{staticClass:"ma-0 text--disabled"},[_vm._v(_vm._s(_vm.title))]),_c('h4',[_c('trev-number-ticker',{attrs:{"number":_vm.mainNumber}})],1),_c('div',{staticClass:"d-flex justify-space-between flex-wrap align-center"},[_c('div',{staticClass:"d-flex align-center"},[_c('div',{class:'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
            (_vm.percentage.includes('-')
              ? ' danger '
              : ' success '),staticStyle:{"height":"24px","width":"24px"}},[_c('v-icon',{class:'caption' +
              (_vm.percentage.includes('-')
                ? ' rotate90 red--text '
                : 'success--text')},[_vm._v(" mdi-call-made ")])],1),_c('span',{class:_vm.percentage.includes('-')
              ? 'red--text'
              : 'success--text'},[_vm._v(_vm._s(_vm.percentage)+"%")])]),_c('div',[_c('p',{staticClass:"ma-0"},[_vm._v(_vm._s(_vm.bottomText))])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }