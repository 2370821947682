<template>
  <number :from="0" :to="number" :duration="duration" :format="formatNumber">

  </number>
</template>

<script>
export default {
  props: {
    number: {
      type: Number,
      default: 0,
    },
    duration: {
      type: Number,
      default: 2
    }
  },
  created() {
    this.displayNumber = this.number ? this.number : 0;
  },

  watch: {
    number() {
      clearInterval(this.interval);

      if (this.number == this.displayNumber) {
        return;
      }

      this.interval = window.setInterval(() => {
        if (this.displayNumber != this.number) {
          var change = (this.number - this.displayNumber) / 10;
          change = change >= 0 ? Math.ceil(change) : Math.floor(change);
          this.displayNumber = this.displayNumber + change;
        }
      }, 5);
    }
  },
  methods: {
    formatNumber(number) {
      return number.toFixed(2);
    },
  },
  data() {
    return {
      displayNumber: 0,
      interval: false,
    };
  },
};
</script>

<style></style>