var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.$store.getters.hasScope('Ticket.Read'))?_c('v-row',[_c('v-col',[_c('contact-ticket-count-widget',{attrs:{"title":"Tickets","bottomText":"This Month","mainNumber":_vm.ticketsThisMonth,"percentage":_vm.piTicketsThisMonth}})],1),_c('v-col',[_c('contact-ticket-count-widget',{attrs:{"title":"Tickets","bottomText":"Last Month","mainNumber":_vm.ticketsLastMonth,"percentage":_vm.piTicketsLastMonth}})],1),_c('v-col',[_c('contact-ticket-count-widget',{attrs:{"title":"Minutes Used","bottomText":"This Month","mainNumber":_vm.minutesThisMonth,"percentage":_vm.piMinutesThisMonth}})],1),_c('v-col',[_c('contact-ticket-count-widget',{attrs:{"title":"Minutes Used","bottomText":"Last Month","mainNumber":_vm.minutesLastMonth,"percentage":_vm.piMinutesLastMonth}})],1)],1):_vm._e(),(_vm.$store.getters.hasScope('Ticket.Read'))?_c('v-row',[_c('v-col',[_c('trev-cool-table',{ref:"ticketTable",attrs:{"courier":_vm.$courier.Contacts.withId(_vm.contactId).ZohoTickets,"isDense":true,"additionalQueryStrings":[{ key: 'status', value: 'Open,On Hold' }],"dateStart":_vm.thisMonthDates.startDate.toISOString(),"cardTextClasses":'pt-0 pr-0 pl-0 pb-0',"dateEnd":_vm.thisMonthDates.endDate.toISOString(),"headers":[
          { text: '#', value: 'ticketNumber' },
          { text: 'Status', value: 'status' },
          { text: 'Added At', value: 'dateAdded' },
          { text: 'Subject', value: 'subject' },
          { text: '', value: 'actions' } ],"defaultPageSize":10,"hideTitle":true},scopedSlots:_vm._u([{key:"item.dateAdded",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(new Date(item.dateAdded).toLocaleString())+" ")]}},{key:"item.subject",fn:function(ref){
        var item = ref.item;
return [_c('a',{attrs:{"href":item.ticketLink,"target":"_blank"}},[_vm._v(" "+_vm._s(item.subject)+" ")])]}}],null,false,3929522183)})],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }