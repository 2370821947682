<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col cols="4">
          <v-card class="text-center">
            <v-avatar size="162" color="primary" class="mt-6">
              <v-img
                src="https://s3.eu-central-1.wasabisys.com/cc-static-assets/web/trevor/user.png"
              ></v-img>
            </v-avatar>
            <v-card-text class="text-center">
              <span class="text-h3">
                {{ contact.firstName + " " + contact.lastName }}
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="body-1"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-check-decagram</v-icon
                    >
                  </template>
                  <span>Thomas is well cool</span>
                </v-tooltip>
              </span>
              <div class="text-h5">
                {{ contact.jobTitle }}
              </div>
              <p class="text--disabled mb-0">
                Added By: {{ contact.addedBy }} @
                {{ new Date(contact.dateAdded).toLocaleDateString("en-GB") }}
              </p>
            </v-card-text>
          </v-card>
          <v-card class="mt-6">
            <v-list dense>
              <v-list-item v-if="parentCompany.id">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Company </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn
                          small
                          outlined
                          color="primary"
                          :to="{
                            name: 'Company Dashboard',
                            params: { companyId: parentCompany.id },
                          }"
                        >
                          {{ parentCompany.name }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="parentCompany.id">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Zoho Company </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn
                          color="info"
                          outlined
                          class="mr-2"
                          small
                          v-if="parentCompany.zohoCRMId"
                          :href="
                            'https://www.coffeecupsupport.com/support/coffeecupsolutions/ShowHomePage.do#Contacts/dv/' +
                            parentCompany.zohoCRMId
                          "
                        >
                          Zoho CRM
                        </v-btn>
                        <v-btn
                          v-if="parentCompany.zohoDeskId"
                          color="info"
                          outlined
                          small
                          :href="
                            'https://www.coffeecupsupport.com/support/coffeecupsolutions/ShowHomePage.do#Contacts/dv/' +
                            parentCompany.zohoDeskId
                          "
                        >
                          Zoho Desk
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Company Address </span>
                      </v-col>
                      <v-col class="py-0">
                        <span class="text-h6 grey--text">
                          {{ getShortAddress }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card>
        </v-col>
        <v-col cols="8">
          <v-card>
            <v-list dense>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Full Name </span>
                      </v-col>
                      <v-col class="py-0">
                        <span class="text-h6 grey--text">
                          {{ contact.firstName + " " + contact.lastName }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Email Address </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn
                          class="grey--text"
                          outlined
                          :href="'mailto:' + contact.emailAddress"
                        >
                          {{ contact.emailAddress }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="contact.mobileNumber">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Mobile Number </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn
                          text
                          class="text-h6 grey--text"
                          :href="'tel:' + contact.mobileNumber"
                        >
                          {{ contact.mobileNumber }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="contact.landLine">
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Land Line </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn
                          text
                          :href="'tel:' + contact.landLine"
                          class="text-h6 grey--text"
                        >
                          {{ contact.landLine }}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item
                v-if="isVIP || isServiceNews || isBilling || isSupportUser"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row>
                      <v-col class="py-0">
                        <span class="text-h6"> Tags </span>
                      </v-col>
                      <v-col class="py-0">
                        <v-chip
                          class="ma-2"
                          color="amber lighten-1"
                          outlined
                          v-if="isVIP"
                        >
                          <v-icon left> mdi-crown-circle-outline </v-icon>
                          VIP
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="green accent-3"
                          outlined
                          v-if="isApprover"
                        >
                          <v-icon left> mdi-shield-check-outline </v-icon>
                          Approver
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="blue-grey lighten-3"
                          outlined
                          v-if="isServiceNews"
                        >
                          <v-icon left> mdi-newspaper-variant-outline</v-icon>
                          Service News
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="purple accent-3"
                          outlined
                          v-if="isBilling"
                        >
                          <v-icon left> mdi-cash-multiple</v-icon>
                          Billing
                        </v-chip>
                        <v-chip
                          class="ma-2"
                          color="orange accent-3"
                          outlined
                          v-if="isSupportUser"
                        >
                          <v-icon left> mdi-face-agent</v-icon>
                          Support User
                        </v-chip>
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content></v-list-item-content>
                <v-list-item-action>
                  <v-btn
                  v-if="$store.getters.hasScope('Contact.Modify')"
                    class="ma-2"
                    color="warning"
                    @click="showEditContact"
                    :disabled="!contact.id"
                    outlined
                  >
                    <v-icon class="mr-2"> mdi-pencil </v-icon>
                    Edit
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-card>
          <v-card>
            <v-tabs background-color="" v-model="bigTabs" grow class="mt-6">
              <v-tab @click="currentTab = 'contact-dashboard'">
                <v-icon class="mr-2"> mdi-chart-box-outline </v-icon>
                Dashboard
              </v-tab>
              <v-menu open-on-hover offset-y close-delay="500">
                <template v-slot:activator="{ on, attr }">
                  <v-tab
                    v-on="on"
                    v-bind="attr"
                    @click="currentTab = 'contact-tickets'"
                  >
                    <v-icon class="mr-2">mdi-face-agent</v-icon>
                    Support
                  </v-tab>
                </template>
                <v-list dense>
                  <v-list-item-group v-model="currentTab">
                    <v-list-item v-if="$store.getters.hasScope('Ticket.Read')" value="contact-tickets" @click="bigTabs = 1">
                      <v-list-item-icon>
                        <v-icon> mdi-lifebuoy </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Tickets</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="$store.getters.hasScope('Approval.Read')" value="contact-approvals" @click="bigTabs = 1">
                      <v-list-item-icon>
                        <v-icon> mdi-check-decagram-outline </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Approvals</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-menu>
              <v-tab
                @click="
                  currentTab = 'contact-settings';
                  bigTabs = 3;
                "
              >
                <v-icon class="mr-2"> mdi-cog-outline </v-icon>
                Settings
              </v-tab>
            </v-tabs>
            <v-tabs-items v-model="currentTab" class="transparent">
              <v-tab-item value="contact-dashboard" :transition="false">
                <contact-dashboard
                  :contactId="$route.params.contactId"
                ></contact-dashboard>
              </v-tab-item>
              <v-tab-item v-if="$store.getters.hasScope('Ticket.Read')" value="contact-tickets">
                <contact-tickets
                  v-if="contact.parentCompanyId"
                  :companyId="contact.parentCompanyId"
                  class="mt-3"
                  :contactId="$route.params.contactId"
                ></contact-tickets>
              </v-tab-item>
              <v-tab-item v-if="$store.getters.hasScope('Approval.Read')" value="contact-approvals">
                <v-container>
                  <v-row>
                    <v-col>
                      approvals here
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs-items>
          </v-card>
        </v-col>
      </v-row>
      <!-- <p class="text--disabled">
              Actor, musician, songwriter #amanda_nash, mailbox:
              hello@amandanash.com
            </p> -->
    </v-container>
    <v-dialog v-model="isEdit" max-width="70vh" v-if="$store.getters.hasScope('Contact.Modify')">
      <v-card>
        <v-toolbar>
          <v-toolbar-title> Edit Contact </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <trev-contact-add-raw
            ref="contactAdd"
            :companyId="contact.parentCompanyId"
            :contactId="contact.id"
            :resetFormAfterSubmit="false"
          ></trev-contact-add-raw>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="saveContact">
            <v-icon> mdi-floppy </v-icon>
            Update Contact
          </v-btn>
          <v-btn text @click="cancelEdit"> Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TrevContactAddRaw from "../../../../components/contacts/trev-contact-add-raw.vue";
import ContactDashboard from "./Components/ContactDashboard.vue";
import ContactTickets from "./Components/ContactTickets.vue";

export default {
  components: { TrevContactAddRaw, ContactDashboard, ContactTickets },
  computed: {
    getShortAddress(){
      if(!this.parentCompany){
        return '';
      }

      var s = [];

      if(this.parentCompany.firstLine){
        s.push(this.parentCompany.firstLine);
      }

      if(this.parentCompany.secondLine){
        s.push(this.parentCompany.secondLine);
      }

      if(this.parentCompany.postCode){
        s.push(this.parentCompany.postCode);
      }

      return s.join(', ')

    },
    isVIP() {
      return this.contact && this.contact.tags
        ? this.contact.tags.some((x) => x.tag === "VIP" && x.active)
        : false;
    },
    isApprover() {
      return this.contact && this.contact.tags
        ? this.contact.tags.some((x) => x.tag === "Approver" && x.active)
        : false;
    },
    isServiceNews() {
      return this.contact && this.contact.tags
        ? this.contact.tags.some((x) => x.tag === "Service News" && x.active)
        : false;
    },
    isBilling() {
      return this.contact && this.contact.tags
        ? this.contact.tags.some((x) => x.tag === "Billing" && x.active)
        : false;
    },
    isSupportUser() {
      return this.contact && this.contact.tags
        ? this.contact.tags.some((x) => x.tag === "Support User" && x.active)
        : false;
    },
  },
  created() {
    this.getContact();
  },
  methods: {
    async getContactTags() {
      var tags = await this.$courier.Contacts.withId(
        this.$route.params.contactId
      ).Tags.getData(1, 100, "");

      this.contact.tags = tags.payload;
    },
    async saveContact() {
      await this.$refs.contactAdd.saveContact();
      this.cancelEdit();
      this.getContact();
    },
    async getContact() {
      var contact = await this.$courier.Contacts.getById(
        this.$route.params.contactId
      );

      this.contact = contact;

      this.getContactTags();
      this.getContactCompany();
    },
    async getContactCompany() {
      this.parentCompany = await this.$courier.Companies.getById(
        this.contact.parentCompanyId
      );
    },
    cancelEdit() {
      this.isEdit = false;
    },
    async showEditContact() {
      this.editContact = await this.$courier.Contacts.getById(
        this.$route.params.contactId
      );
      this.isEdit = true;
    },
  },
  data() {
    return {
      isEdit: false,
      parentCompany: {},
      contact: {},
      currentTab: "contact-dashboard",
      bigTabs: 0,
    };
  },
};
</script>

<style>
</style>