<template>
  <v-card class="ma-0 mr-4">
    <v-card-text>
      <p class="ma-0 text--disabled">{{title}}</p>
      <h4>
        <trev-number-ticker :number="mainNumber"></trev-number-ticker>        
      </h4>
      <div class="d-flex justify-space-between flex-wrap align-center">
        <div class="d-flex align-center">
          <div
            :class="
              'mr-2 d-flex align-center justify-center rounded-circle v-sheet lighten-5' +
              (percentage.includes('-')
                ? ' danger '
                : ' success ')
            "
            style="height: 24px; width: 24px"
          >
            <v-icon
              :class="
                'caption' +
                (percentage.includes('-')
                  ? ' rotate90 red--text '
                  : 'success--text')
              "
            >
              mdi-call-made
            </v-icon>
          </div>
          <span
            :class="
              percentage.includes('-')
                ? 'red--text'
                : 'success--text'
            "
            >{{ percentage }}%</span
          >
        </div>
        <div><p class="ma-0">{{bottomText}}</p></div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import trevNumberTicker from '../../../../../components/ui/trev-number-ticker.vue';
export default {
  components: { trevNumberTicker },
    props: {
        title: {
            type: String,
            default: 'title'
        },
        mainNumber: {
            type: Number,
            default: 42069
        },
        percentage: {
            type: String,
            default: '69'
        },
        bottomText: {
            type: String,
            default: 'This Month'
        }

    },
};
</script>

<style>
</style>