<template>
  <v-container fluid>
    <v-row v-if="$store.getters.hasScope('Ticket.Read')">
      <v-col>
        <contact-ticket-count-widget
          title="Tickets"
          bottomText="This Month"
          :mainNumber="ticketsThisMonth"
          :percentage="piTicketsThisMonth"
        ></contact-ticket-count-widget>
      </v-col>
      <v-col>
        <contact-ticket-count-widget
          title="Tickets"
          bottomText="Last Month"
          :mainNumber="ticketsLastMonth"
          :percentage="piTicketsLastMonth"
        ></contact-ticket-count-widget>
      </v-col>
      <v-col>
        <contact-ticket-count-widget
          title="Minutes Used"
          bottomText="This Month"
          :mainNumber="minutesThisMonth"
          :percentage="piMinutesThisMonth"
        ></contact-ticket-count-widget>
      </v-col>
      <v-col>
        <contact-ticket-count-widget
          title="Minutes Used"
          bottomText="Last Month"
          :mainNumber="minutesLastMonth"
          :percentage="piMinutesLastMonth"
        ></contact-ticket-count-widget>
      </v-col>
    </v-row>
    <v-row v-if="$store.getters.hasScope('Ticket.Read')">
      <v-col>
        <trev-cool-table
          ref="ticketTable"
          :courier="
            $courier.Contacts.withId(contactId).ZohoTickets
          "
          :isDense="true"
          :additionalQueryStrings="[{ key: 'status', value: 'Open,On Hold' }]"
          :dateStart="thisMonthDates.startDate.toISOString()"
          :cardTextClasses="'pt-0 pr-0 pl-0 pb-0'"
          :dateEnd="thisMonthDates.endDate.toISOString()"
          :headers="[
            { text: '#', value: 'ticketNumber' },
            { text: 'Status', value: 'status' },
            { text: 'Added At', value: 'dateAdded' },
            { text: 'Subject', value: 'subject' },
            { text: '', value: 'actions' },
          ]"
          :defaultPageSize="10"
          :hideTitle="true"
        >
          <template v-slot:item.dateAdded="{ item }">
            {{ new Date(item.dateAdded).toLocaleString() }}
          </template>
          <template v-slot:item.subject="{ item }">
            <a :href="item.ticketLink" target="_blank">
              {{ item.subject }}
            </a>
          </template>
        </trev-cool-table>          
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ContactTicketCountWidget from "./ContactTicketCountWidget.vue";
import * as GeneralUtilities from "../../../../../utilities/GeneralUtilities";
import TrevCoolTable from '../../../../../components/tables/trev-cool-table.vue';
export default {
  components: { ContactTicketCountWidget, TrevCoolTable },
  props: {
    contactId: {
      type: String,
      default: "",
    },
  },
  computed: {
    piMinutesThisMonth() {
      if (this.minutesThisMonth && this.minutesLastMonth) {
        return Math.round(
          GeneralUtilities.getPercentageIncrease(
            this.minutesLastMonth,
            this.minutesThisMonth
          )
        ).toString();
      } else {
        return "0";
      }
    },
    piMinutesLastMonth() {
      if (this.minutesLastMonth && this.minutesMonthBeforeLast) {
        return Math.round(
          GeneralUtilities.getPercentageIncrease(
            this.minutesLastMonth,
            this.minutesMonthBeforeLast
          )
        ).toString();
      } else {
        return "0";
      }
    },
    piTicketsThisMonth() {
      if (this.ticketsThisMonth && this.ticketsLastMonth) {
        return Math.round(
          GeneralUtilities.getPercentageIncrease(
            this.ticketsThisMonth,
            this.ticketsLastMonth
          )
        ).toString();
      } else {
        return "0";
      }
    },
    piTicketsLastMonth() {
      if (this.ticketsLastMonth && this.ticketsMonthBeforeLast) {
        return Math.round(
          GeneralUtilities.getPercentageIncrease(
            this.ticketsLastMonth,
            this.ticketsMonthBeforeLast
          )
        ).toString();
      } else {
        return "0";
      }
    },
  },
  created() {
    this.generateDates();
    this.getAllTicketStats();
  },
  methods: {
    generateDates() {
      var now = new Date();

      this.thisMonthDates = GeneralUtilities.getMinMaxDatesByDate(now);
      now.setMonth(now.getMonth() - 1);
      this.lastMonthDates = GeneralUtilities.getMinMaxDatesByDate(now);

      now.setMonth(now.getMonth() - 1);
      this.monthBeforeLastDates = GeneralUtilities.getMinMaxDatesByDate(now);
    },
    getAllTicketStats() {
      //this months minutes
      this.getMinutesByDates(
        this.thisMonthDates.startDate,
        this.thisMonthDates.endDate
      ).then((response) => {
        this.minutesThisMonth = response;
      });

      //last minutes dates
      this.getMinutesByDates(
        this.lastMonthDates.startDate,
        this.lastMonthDates.endDate
      ).then((response) => {
        this.minutesLastMonth = response;
      });

      //month before last minutes
      this.getMinutesByDates(
        this.monthBeforeLastDates.startDate,
        this.monthBeforeLastDates.endDate
      ).then((response) => {
        this.minutesMonthBeforeLast = response;
      });

      //this months ticket count
      this.getTicketsByDates(
        this.thisMonthDates.startDate,
        this.thisMonthDates.endDate
      ).then((response) => {
        this.ticketsThisMonth = response;
      });

      //last months tickets
      this.getTicketsByDates(
        this.lastMonthDates.startDate,
        this.lastMonthDates.endDate
      ).then((response) => {
        this.ticketsLastMonth = response;
      });

      //month before last tickets
      this.getTicketsByDates(
        this.monthBeforeLastDates.startDate,
        this.monthBeforeLastDates.endDate
      ).then((response) => {
        this.ticketsMonthBeforeLast = response;
      });
    },
    async getMinutesByDates(startDate, endDate) {
      var resp = await this.$courier.Contacts.GetTicketTimeUsedByRange(
        this.contactId,
        startDate,
        endDate
      );
      return resp;
    },
    async getTicketsByDates(startDate, endDate) {
      var resp = await this.$courier.Contacts.GetTicketsByRange(
        this.contactId,
        1,
        1,
        "",
        startDate,
        endDate
      );
      return resp.totalCount;
    },
  },
  data() {
    return {
      thisMonthDates: { startDate: "", endDate: "" },
      lastMonthDates: { startDate: "", endDate: "" },      
      monthBeforeLastDates: { startDate: "", endDate: "" },
      minutesMonthBeforeLast: 0,
      minutesLastMonth: 0,
      minutesThisMonth: 0,
      ticketsMonthBeforeLast: 0,
      ticketsLastMonth: 0,
      ticketsThisMonth: 0,
    };
  },
};
</script>

<style>
</style>